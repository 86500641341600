import React from "react";
import { IconButton, Typography } from "@material-tailwind/react";

import Pagination from "./Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { Testimonial, TestimonialProps } from "../Interface/TestimonialProps";
import ModalEditTestimonials from "../Modals/ModalEditTestimonial";

interface Props {
    data: TestimonialProps;
    handleGetData: Function;
    nextPage: Function
}

export default function TableUserTestimonials({ data, handleGetData, nextPage }: Props) {
    const { t } = useTranslation();
    const [openModal, setOpenModal] = React.useState(false);
    const [dataFiltered, setDataFiltered] = React.useState<{
        message: string,
        id: string
    }>({
        message: "",
        id: ""
    });

    const onClickEdit = async (datas: Testimonial) => {
        setDataFiltered({
            message: datas?.message,
            id: datas?.id
        });
        setOpenModal(true);
    }

    const handleCloseModal = () => {
        setDataFiltered({
            message: "",
            id: ""
        });
        handleGetData()
        setOpenModal(false);
    }

    return (
        <div className="table-container mb-2">
            <ModalEditTestimonials open={openModal} setOpen={() => handleCloseModal()} data={{ message: dataFiltered.message }} currentId={dataFiltered.id} />
            <table className="w-full table-auto overflow-x-scroll">
                <thead>
                    <tr>
                        <th className="border-b border-blue-gray-50 py-3 text-left px-5">
                            <Typography placeholder="" variant="small" className="text-[10px] font-bold uppercase text-blue-gray-400 text-left">
                                {t("message")}
                            </Typography>
                        </th>
                        <th className="border-b border-blue-gray-50 py-3 px-5 text-center">
                            <Typography placeholder="" variant="small" className="text-[10px] font-bold uppercase text-blue-gray-400">
                                {t("actions")}
                            </Typography>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.data !== undefined &&
                        data.data.map((element, key: number) => {
                            const className = `py-3 px-5 ${key === data.data.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                            return (
                                <tr
                                    className={`example-element-row ${key % 2 === 0 ? "bg-blue-gray-50" : ""}`}
                                >
                                    <td className={className}>
                                        <Typography
                                            placeholder=""
                                            variant="small"
                                            color="blue-gray"
                                            className={
                                                "text-gray-500"
                                            }
                                        >
                                            {element.message ?? "/"}
                                        </Typography>
                                    </td>
                                    <td className={className} style={{ textAlign: "center"}}>
                                        <IconButton placeholder="" className="ml-4" color="orange" size="sm" onClick={() => onClickEdit(element)}>
                                            <FontAwesomeIcon icon="pen" />
                                        </IconButton>
                                    </td>
                                </tr>
                            )
                        })}
                </tbody>
            </table>
            <Pagination
                handlePageChange={nextPage}
                itemsPerPage={data.pagination.parPage ?? 10}
                currentPage={data.pagination.current}
                totalItems={data.pagination.totalItems}
                startIndex={data.pagination.current * (data.pagination.parPage ?? 10) - (data.pagination.parPage ?? 10)}
                endIndex={
                    data.pagination.current * (data.pagination.parPage ?? 10) - 1 > data.pagination.totalItems
                        ? data.pagination.totalItems - 1
                        : data.pagination.current * (data.pagination.parPage ?? 10) - 1
                }
            />
        </div>
    );
};