import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/NavBar/Header";
import TableSupport from "../../components/Tables/TableSupport";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import ContactAPI from "../../services/API/clients/ContactAPI";
import { Headers, HeadersGalleries, HeadersPicture, HeadersPortfolio } from "./Header";
import { Alert, Tab, TabPanel, Tabs, TabsBody, TabsHeader } from "@material-tailwind/react";
import TableReports from "../../components/Tables/TableReports";

export default function Support() {
    const { t } = useTranslation();
    const [currentTab, setCurrentTab] = React.useState<string>("contact");
    const [list, setList] = React.useState<{
        pagination: {
            current: number,
            last: number,
            totalItems: number,
            parPage: number
        }
        data: []
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0
        },
        data: []
    
    });
    const [listPortfolio, setListPortfolio] = React.useState<{
        pagination: {
            current: number,
            last: number,
            totalItems: number,
            parPage: number
        }
        data: []
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0
        },
        data: []
    
    });
    const [listGallery, setListGallery] = React.useState<{
        pagination: {
            current: number,
            last: number,
            totalItems: number,
            parPage: number
        }
        data: []
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0
        },
        data: []
    
    });
    const [listPicture, setListPicture] = React.useState<{
        pagination: {
            current: number,
            last: number,
            totalItems: number,
            parPage: number
        }
        data: []
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0
        },
        data: []
    
    });
    document.title = `ezoom | ${t("communications")}`;

    const getList = async () => {
        const response = await ContactAPI.support();
        setList(response.body);
        const resp = await ContactAPI.portfolioReport();
        setListPortfolio(resp.body);
        const res = await ContactAPI.galleryReport();
        setListGallery(res.body);
        const r = await ContactAPI.pictureReport();
        setListPicture(r.body);
    }

    useEffect(() => {
        getList()
    }, [])

    return (
        <div className="min-h-screen bg-gray-50 relative">
            <Header />
            <div className="flex flex-col h-full bg-gray-50 -mt-4 pt-4">
                <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-12 bg-white z-30 border-b border-gray-200">
                    <TitlePage><FontAwesomeIcon icon="envelope-open-text" className="text-orange-500" /> {t("communications")}</TitlePage>
                </div>
                <WrapperGrayZone pt="4">
                    <div className="w-full max-w-7xl mx-auto md:px-8 flex flex-col gap-4">
                        <div className="w-full overflow-x-auto">
                            <Tabs value="contact">
                                <TabsHeader placeholder="">
                                    <Tab placeholder="" value="contact" onClick={() => setCurrentTab("contact")}>
                                        {t("contact")}
                                    </Tab>
                                    <Tab placeholder="" value="reportPortfolios" onClick={() => setCurrentTab("reportPortfolios")}>
                                        {t("reportPortfolios")}
                                    </Tab>
                                    <Tab placeholder="" value="reportGallery" onClick={() => setCurrentTab("reportGallery")}>
                                        {t("reportGallery")}
                                    </Tab>
                                    <Tab placeholder="" value="reportPictures" onClick={() => setCurrentTab("reportPictures")}>
                                        {t("reportPictures")}
                                    </Tab>
                                </TabsHeader>
                                <TabsBody placeholder="">
                                    <TabPanel value="contact" className="mt-2">
                                        {list?.data?.length === 0 
                                            ? <Alert variant="ghost" color="gray">{t("noMessage")}</Alert>
                                            : <TableSupport header={Headers} data={list?.data ?? []} itemsPerPage={list?.pagination?.parPage ?? 0} />
                                        }
                                    </TabPanel>
                                    <TabPanel value="reportPortfolios" className="mt-2">
                                        {listPortfolio?.data?.length === 0 
                                            ? <Alert variant="ghost" color="gray">{t("noReport")}</Alert>
                                            : <TableReports header={HeadersPortfolio} data={listPortfolio?.data ?? []} itemsPerPage={listPortfolio?.pagination?.parPage ?? 0} />
                                        }
                                    </TabPanel>
                                    <TabPanel value="reportGallery" className="mt-2">
                                        {listGallery?.data?.length === 0 
                                            ? <Alert variant="ghost" color="gray">{t("noReport")}</Alert>
                                            : <TableReports header={HeadersGalleries} data={listGallery?.data ?? []} itemsPerPage={listGallery?.pagination?.parPage ?? 0} />
                                        }
                                    </TabPanel>
                                    <TabPanel value="reportPictures" className="mt-2">
                                        {listPicture?.data?.length === 0 
                                            ? <Alert variant="ghost" color="gray">{t("noReport")}</Alert>
                                            : <TableReports header={HeadersPicture} data={listPicture?.data ?? []} itemsPerPage={listPicture?.pagination?.parPage ?? 0} />
                                        }
                                    </TabPanel>
                                </TabsBody>
                            </Tabs>
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
        </div>
    );
}
