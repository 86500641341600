import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { IconButton, Input } from "@material-tailwind/react";
import GradientButton from "../../components/Form/Button/GradientButtonBig";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Storage } from "../../services/storage";
import Auth from "../../services/API/clients/AuthAPI";
import { toast } from "react-toastify";
import { useGoogleLogin } from "@react-oauth/google";
import Modal2fa from "../../components/Modals/Modal2fa";

export default function Signin() {
    const { t } = useTranslation();
    document.title = `ezoom | ${t("signin")}`;
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [token, setToken] = useState("");
    const [user, setUser] = useState<{ email: string, password: string }>({
        email: "",
        password: ""
    });


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setUser({
            ...user,
            [e.target.name]: e.target.value
        });
    }

    const handleSignin = async (e: any) => {
        e.preventDefault();
        if (loading) return;
        setLoading(true);

        if (user.email === "" || user.password === "") {
            toast.warning(t("empty_fields"));
            return;
        }

        const datas = {
            username: user.email,
            password: user.password
        }

        const response = await Auth.signin(datas);

        if (response.status === 200) {
            if (response.body?.totpToken) {
                setToken(response.body.totpToken);
                setOpen(true);
            } else {
                if (response.body.roles.includes("role:admin") || response.body.roles.includes("role:moderator")) {    
                    Storage.setToken(response.body.token);
                    Storage.setRoles(response.body.roles);
                    Storage.setRefreshToken(response.body.refresh_token);
                    setLoading(false);
                    toast.success(t("signin_success"));
                    window.location.href = "/admin";
                } else {
                    toast.error(t("bad_credentials"));
                }
            }   
        } else if (response.body.code === 401) {
            if (response.body.message === "Your user account is disabled.") {
                toast.error(t("user_disabled"));
                setLoading(false);
            } else {
                toast.error(t("bad_credentials"));
                setLoading(false);
            }
        } else {
            toast.error(t("signin_error"));
            setLoading(false);
        }
    }

    const handleChange2fa = () => {
        setOpen(!open);
        if (open) {
            setLoading(false);
        }
    }

    return (
        <div className="min-h-full">
            <Modal2fa open={open} setOpen={handleChange2fa} token={token} />
            <div className="hidden md:block h-full" style={{ backgroundImage: "url('http://beta.ezoom.ch/pic/70oNVhVWmI')", backgroundAttachment: "fixed", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", width: "100vmax", zIndex: -1, position: "fixed", top: 0, left: "50%", transform: 'translate(-50%, 0)', pointerEvents: 'none', backgroundPositionX: 'center'}}></div>
            <div className="min-h-screen flex justify-center items-center">
                <div className="relative w-screen h-screen md:min-h-auto mt-0 mb-0 md:h-auto md:w-2/3 xl:w-1/3 bg-white rounded-0 md:rounded-lg md:mt-8 md:mb-16 px-8 py-12 mx-auto shadow-xl flex justify-center flex-col max-w-3xl">
                    <div className="w-20 mx-auto mb-4">
                        <svg version="1.1" id="Calque_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                            viewBox="0 0 33.7 33.7">
                        <path fill="#F49D07" d="M30.8,14.1c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9c1.6,0,2.9-1.3,2.9-2.9S32.4,14.1,30.8,14.1z"/>
                        <path fill="#F49D07" d="M29.7,23.2c-0.6-0.5-1.4-0.7-2.1-0.6c-0.8,0.1-1.4,0.5-1.9,1.1c-0.5,0.7-1.1,1.3-1.7,1.8c-2,1.6-4.4,2.5-7,2.6
                            c-5.9,0.1-11.1-4.9-11.3-10.8c-0.1-3,1-5.9,3.1-8.1s5-3.4,8-3.4c2.4,0,4.8,0.8,6.8,2.3l-8.5,6.6c-0.6,0.5-1,1.1-1.1,1.9
                            c-0.1,0.8,0.1,1.5,0.6,2.1s1.1,1,1.9,1.1s1.5-0.1,2.1-0.6l11.1-8.6c0.6-0.5,1-1.1,1.1-1.9s-0.1-1.5-0.6-2.1
                            c-0.5-0.7-1.1-1.3-1.7-1.9c-3.7-3.6-8.8-5.2-14-4.5C7,1.3,1,7.5,0.1,15c-0.5,4.9,0.9,9.5,4.2,13.1c3.2,3.6,7.8,5.6,12.6,5.6
                            c4.3,0,8.4-1.7,11.6-4.7c0.6-0.6,1.2-1.2,1.7-1.9c0.5-0.6,0.7-1.4,0.6-2.1S30.3,23.6,29.7,23.2z"/>
                        </svg>
                    </div>
                    <h1 className="text-3xl mb-6 text-center">{t("signin")}</h1>
                    <form onSubmit={handleSignin} className="flex flex-col w-full gap-4 mb-6">
                        <Input crossOrigin="" name="email" type="email" color="orange" label={t("mail")} className="rounded-md font-sans" value={user.email} onChange={handleChange} />
                        <Input crossOrigin="" name="password" type="password" color="orange" label={t("pwd")} className="rounded-md font-sans" value={user.password} onChange={handleChange} />
                        <button className="mx-auto w-full">
                            <GradientButton text="connect" />
                        </button>
                    </form>
                    {/* <div className="text-center uppercase my-4 flex mx-auto w-3/4 items-center">
                        <div className="flex-1 border-b border-gray-400 mr-1/4"></div>
                        <span className="text-gray-400 mx-2">{ t("or") }</span>
                        <div className="flex-1 border-b border-gray-400 ml-1/4"></div>
                    </div> */}
                    {/* <FacebookLogin
                        appId="1217121976359157"
                        fields="name,email,picture"
                        responseType='code'
                        callback={responseFacebook}
                        render={renderProps => (
                            <button onClick={renderProps.onClick} className='border-2 border-blue-900 inline w-auto transition-all hover:bg-blue-900 hover:text-white py-2 px-4 rounded-full text-blue-900 mt-2'>
                                {t("signinfb")}
                            </button>
                        )}
                        version='19.0'
                    />
                    <button onClick={() => loginGoogle()} className="border-2 border-gray-900 inline w-auto transition-all hover:bg-gray-900 hover:text-white py-2 px-4 rounded-full text-gray-900 mt-4">{t("signingl")}</button> */}
                </div>
            </div>
        </div>
    );
}
