import React from "react";
import { IconButton, Typography } from "@material-tailwind/react";

import Pagination from "./Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DaumProps, KeywordsProps } from "../Interface/KeywordsProps";
import { useTranslation } from "react-i18next";
import ModalKeywords from "../Modals/ModalKeywords";

interface Props {
    data: KeywordsProps;
    handleGetData: Function;
    nextPage: Function
}

export default function TableKeywords({ data, handleGetData, nextPage }: Props) {
    const { t } = useTranslation();
    const [openModal, setOpenModal] = React.useState(false);
    const [dataFiltered, setDataFiltered] = React.useState<DaumProps>({
        customLabel: "",
        index: 0
    });

    const onClickEdit = async (action: string, data: DaumProps) => {
        console.log(action, data);
        setDataFiltered(data);
        setOpenModal(true);
    }

    return (
        <div className="table-container mt-4 mb-2">
            <ModalKeywords open={openModal} setOpen={() => setOpenModal(false)} data={dataFiltered} handleGetData={handleGetData} />
            <table className="w-full min-w-[640px] table-auto overflow-x-scroll">
                <thead>
                    <tr>
                        <th className="border-b border-blue-gray-50 py-3 text-left px-5">
                            <Typography placeholder="" variant="small" className="text-[11px] font-bold uppercase text-blue-gray-400 text-left">
                                {t("suggets")}
                            </Typography>
                        </th>
                        <th className="border-b border-blue-gray-50 py-3 text-left px-5">
                            <Typography placeholder="" variant="small" className="text-[11px] font-bold uppercase text-blue-gray-400 text-left">
                                {t("cat")}
                            </Typography>
                        </th>
                        <th className="border-b border-blue-gray-50 py-3 px-5 text-center">
                            <Typography placeholder="" variant="small" className="text-[11px] font-bold uppercase text-blue-gray-400">
                                {t("actions")}
                            </Typography>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {data.data !== undefined &&
                        data.data.map((element, key: number) => {
                            const className = `py-3 px-5 ${key === data.data.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                            return (
                                <tr
                                    className={`example-element-row ${key % 2 === 0 ? "bg-blue-gray-50" : ""}`}
                                >
                                    <td className={className}>
                                        <Typography
                                            placeholder=""
                                            variant="small"
                                            color="blue-gray"
                                            className={
                                                "text-gray-500"
                                            }
                                        >
                                            {element.customLabel ?? "/"}
                                        </Typography>
                                    </td>
                                    <td className={className}>
                                        <Typography
                                            placeholder=""
                                            variant="small"
                                            color="blue-gray"
                                            className={
                                                "text-gray-500"
                                            }
                                        >
                                            {element?.parent?.category?.frLabel ?? "/"}
                                        </Typography>
                                    </td>
                                    <td className={className} style={{ textAlign: "center"}}>
                                        <IconButton placeholder="" className="ml-4" color="green" size="sm" onClick={() => onClickEdit("refuse", element)}>
                                            <FontAwesomeIcon icon="check" />
                                        </IconButton>
                                    </td>
                                </tr>
                            )
                        })}
                </tbody>
            </table>
            <Pagination
                handlePageChange={nextPage}
                itemsPerPage={data.pagination.parPage}
                currentPage={data.pagination.current}
                totalItems={data.pagination.totalItems}
                startIndex={data.pagination.current * data.pagination.parPage - data.pagination.parPage}
                endIndex={
                    data.pagination.current * data.pagination.parPage - 1 > data.pagination.totalItems
                        ? data.pagination.totalItems - 1
                        : data.pagination.current * data.pagination.parPage - 1
                }
            />
        </div>
    );
};