import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Header from "../../components/NavBar/Header";
import TitlePage from "../../components/TitlePage";
import WrapperGrayZone from "../../components/WrapperGrayZone";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import UsersAPI from "../../services/API/clients/UsersAPI";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, CardFooter, CardHeader, Chip, Typography, Tabs, TabsHeader, TabsBody, Tab, TabPanel, Spinner, Tooltip } from "@material-tailwind/react";
import DefaultProfilePicture from "../../assets/img/default-profile.png";
import { toast } from "react-toastify";
import { HeaderCardDetail } from "./HeaderCardDetail";
import moment from "moment";

export default function CardDetail() {
    const { t } = useTranslation();
    const { id } = useParams();
    const [user, setUser] = useState<any>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [navigation, setNavigation] = useState<{
        pagination: {
            current: number;
            last: number;
            totalItems: number;
            parPage: number;
        };
        data: {
            requestDetails: {
                operatingSystem: string;
                deviceType: string;
                browser: string;
                country: {
                    code: string;
                    currency: string;
                    enLabel: string;
                    frLabel: string;
                    alpha2: string;
                };
            };
            createdAt: string;
        }[];
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0,
        },
        data: [],
    });
    const [order, setOrder] = useState<{
        pagination: {
            current: number;
            last: number;
            totalItems: number;
            parPage: number;
        },
        data: {
            status: string,
            createdAt: string,
            paymentMethod: string,
            paymentMethodName: string,
            displayText: string,
            amount: number,
            renewal: boolean
        }[]
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0,
        },
        data: [],
    });
    const [subs, setSubs] = useState<{
        pagination: {
            current: number;
            last: number;
            totalItems: number;
            parPage: number;
        },
        data: {
            status: string,
            createdAt: string,
            paymentMethod: string,
            paymentMethodName: string,
            displayText: string,
            amount: number,
            renewal: boolean
        }[]
    }>({
        pagination: {
            current: 0,
            last: 0,
            totalItems: 0,
            parPage: 0,
        },
        data: [],
    });

    const returnColor = () => {
        if (user?.plan === undefined) return;
        if (user?.plan === "PRO") return "bg-black text-white";
        else if (user?.plan === "PHOTOGRAPHER") return " bg-white text-black border border-black";
        else if (user?.plan === "PREMIUM") return " bg-white text-black border border-black";
        else return " bg-white text-black border border-black";
    };

    const returnValue = () => {
        if (user?.plan === undefined) return;
        if (user?.plan === "PRO") return t("pro");
        else if (user?.plan === "PHOTOGRAPHER") return t("photographer");
        else if (user?.plan === "PREMIUM") return t("premium");
        else return t("user");
    };

    const returnIcon = () => {
        if (user?.plan === undefined) return;
        if (user?.plan === "PRO") return <FontAwesomeIcon icon="crown" className="h-3.5 mt-0.5" />;
        else if (user?.plan === "PREMIUM") return <FontAwesomeIcon icon="star" className="h-3.5 mt-0.5" />;
        else return;
    };

    const fetchData = async () => {
        if (!id) return;
        const response = await UsersAPI.user(id);
        if (response.status === 200) {
            setUser(response.body);
            setIsLoading(false);
        }
    };

    const fetchNavigation = async () => {
        if (!id) return;
        const response = await UsersAPI.loginLogs(id);
        if (response.status === 200) {
            setNavigation(response.body);
        }
    };

    const fetchOrder = async () => {
        if (!id) return;
        const response = await UsersAPI.orderTransaction(id);
        if (response.status === 200) {
            setOrder(response.body);
        }
    };

    const fetchSubs = async () => {
        if (!id) return;
        const response = await UsersAPI.subTransaction(id);
        if (response.status === 200) {
            setSubs(response.body);
        }
    };

    useEffect(() => {
        fetchData();
        fetchNavigation();
        fetchOrder();
        fetchSubs();
    }, [id]);

    const disabledUser = async () => {
        if (!id) return;
        const datas = {
            enabled: !user?.enabled,
        }
        const response = await UsersAPI.changeStatus(id, datas);
        if (response.status === 200 || response.status === 201) {
            if (!user?.enabled) 
                toast.success(t("successEnable"));
            else
                toast.success(t("successDisable"));
            fetchData();
        } else {
            toast.error(t("errorDisable"));
        }
    };

    const verifUser = async () => {
        if (!id) return;
        const response = await UsersAPI.confirmData(id);
        if (response.status === 200 || response.status === 201) {
            toast.success(t("successVerify"));
            fetchData();
        } else {
            toast.error(t("errorVerify"));
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 relative">
            <Header />
            <div className="flex flex-col h-full bg-gray-50 -mt-4 pt-4">
                <div className="flex flex-row md:flex-col items-center justify-between w-full sticky md:static top-12 bg-white z-30 border-b border-gray-200">
                    <TitlePage>
                        <FontAwesomeIcon icon="user" className="text-orange-500" /> {t("users")}
                    </TitlePage>
                </div>
                <WrapperGrayZone pt="4">
                    <div className="w-full max-w-7xl mx-auto px-2 md:px-8 flex flex-col gap-4 md:gap-0 md:flex-row flex-wrap">
                        <div className="w-full md:w-96 md:pr-2 md:pb-2">
                            <Card placeholder="" className="w-full h-full flex flex-col justify-center">
                                <CardHeader placeholder="" floated={false} className="h-[150px] w-[150px] rounded-full mx-auto">
                                    <img
                                        src={user?.portfolio?.picture ?? DefaultProfilePicture}
                                        alt="profile"
                                        className="w-full h-full object-cover object-center rounded-full"
                                    />
                                </CardHeader>
                                <CardBody placeholder="" className="text-center">
                                    <Typography placeholder="" variant="h4" color="blue-gray" className="mb-2">
                                        {user?.firstname} {user?.lastname}
                                    </Typography>
                                    <Typography placeholder="" color="blue-gray" className="font-medium" textGradient>
                                        {user?.email}
                                    </Typography>
                                    <Typography placeholder="" color="blue-gray" className="font-medium w-fit mx-auto mt-2">
                                        <Chip className={`w-fit h-fit ${returnColor()}`} variant="ghost" value={returnValue()} icon={returnIcon()} />
                                    </Typography>
                                </CardBody>
                                {isLoading ? (
                                    <CardFooter placeholder="" className="flex justify-center gap-4 pt-2">
                                        <Spinner color="orange" />
                                    </CardFooter>
                                ) : (
                                    <CardFooter placeholder="" className="flex justify-center gap-4 pt-2">
                                        {user?.portfolio?.publicLink ? (
                                            <a href={`https://beta.ezoom.ch/${user?.portfolio?.publicLink}/photos`}>
                                                <Button placeholder="" color="orange">
                                                    {t("portfolio")}
                                                </Button>
                                            </a>
                                        ) : (
                                            <Tooltip content={t("noPortfolio")} placement="right">
                                                <Button placeholder="" color="orange" disabled>
                                                    {t("portfolio")}
                                                </Button>
                                            </Tooltip>
                                        )}
                                        
                                        <Button placeholder="" color={!user?.enabled ? "green" : "red"} onClick={() => disabledUser()}>
                                            {t(!user?.enabled ? "ENABLE" : "DISABLE")}
                                        </Button>
                                    </CardFooter>
                                )}
                            </Card>
                        </div>
                        <div className="w-full md:w-[calc(100%-24rem)] md:pl-2 md:pb-2">
                            <Card placeholder="" className="w-full h-full">
                                <CardBody placeholder="" className="overflow-x-auto">
                                    <table className="h-full w-full table-auto text-left">
                                        {HeaderCardDetail.map((exif) => {
                                            return (
                                                <tr key={exif.id}>
                                                    <th className="border-b p-4 border-r border-blue-gray-100/80 rounded-tl">
                                                        <Typography
                                                            placeholder=""
                                                            variant="small"
                                                            color="blue-gray"
                                                            className="font-normal leading-none opacity-70 rounded-tl"
                                                        >
                                                            {t(exif.id)}
                                                        </Typography>
                                                    </th>
                                                    <td className="p-4 border-b border-blue-gray-100/80">
                                                        <Typography placeholder="" variant="small" color="blue-gray" className="font-normal opacity-60">
                                                            {exif.id === "pseudo" && (user.portfolio?.name ?? "/")}
                                                            {exif.id === "country" && (user?.country?.frLabel ?? "/") }
                                                            {exif.id === "address" && (user?.place?.label ?? "/") }
                                                            {(exif.id !== "pseudo" && exif.id !== "country" && exif.id !== "address") ? user[exif.col] ?? "/" : ""}
                                                        </Typography>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </table>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="w-full md:w-1/3 md:pr-2 md:pb-2">
                            <Card placeholder="" className="w-full h-full flex flex-col justify-start">
                                <CardBody placeholder="" className="text-left">
                                    <Typography placeholder="" variant="h4" color="blue-gray" className="mb-2 text-center">
                                        {t("confirmData")}
                                    </Typography>
                                    <Typography placeholder="" variant="paragraph" color="blue-gray" className="mb-2 flex gap-2 items-center">
                                        <FontAwesomeIcon
                                            icon={user?.emailVerified ? "check" : "x"}
                                            className={`${user?.emailVerified ? "text-green-500" : "text-red-500"}`}
                                        />
                                        {t("email")}
                                    </Typography>
                                    <Typography placeholder="" variant="paragraph" color="blue-gray" className="mb-2 flex gap-2 items-center">
                                        <FontAwesomeIcon
                                            icon={user?.phoneVerified ? "check" : "x"}
                                            className={`${user?.phoneVerified ? "text-green-500" : "text-red-500"}`}
                                        />
                                        {t("phone")}
                                    </Typography>
                                    <Typography placeholder="" variant="paragraph" color="blue-gray" className="mb-2 flex gap-2 items-center">
                                        <FontAwesomeIcon
                                            icon={user?.manualVerification === "verified" ? "check" : "x"}
                                            className={`${user?.manualVerification === "verified" ? "text-green-500" : "text-red-500"}`}
                                        />
                                        {t("data")}
                                    </Typography>
                                    <div className="w-fit mx-auto mt-4">
                                        <Button placeholder="" color="orange" onClick={() => verifUser()}>
                                            {t("VERIFIED")}
                                        </Button>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="w-full md:w-1/3 md:pr-2 md:pb-2">
                            <Card placeholder="" className="w-full h-full flex flex-col justify-start">
                                <CardBody placeholder="" className="text-left">
                                    <Typography placeholder="" variant="h4" color="blue-gray" className="mb-2 text-center">
                                        {t("transactions")}
                                    </Typography>
                                    <Tabs value="orders">
                                        <TabsHeader placeholder="">
                                            <Tab placeholder="" value="orders">
                                                {t("orders")}
                                            </Tab>
                                            <Tab placeholder="" value="subs">
                                                {t("subs")}
                                            </Tab>
                                        </TabsHeader>
                                        <TabsBody placeholder="">
                                            <TabPanel value="orders" className="overflow-y-auto max-h-[200px] h-[200px] mt-2">
                                                {order?.data.length > 0 ? (
                                                    <div className="flex flex-col gap-2">
                                                    {order?.data.map((ordering) => (
                                                        <Card placeholder="" key={ordering.createdAt}>
                                                            <CardBody placeholder="">
                                                                <Typography placeholder="" variant="h5" color="blue-gray" className="mb-2">
                                                                    {ordering.displayText}
                                                                </Typography>
                                                                <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">
                                                                    {moment(ordering.createdAt).format("DD.MM.YYYY HH:mm:ss")}
                                                                </Typography>
                                                                <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">
                                                                    {ordering.paymentMethodName} {ordering.status} <b>{ordering.amount}</b>{" "}{t("chf")}
                                                                </Typography>
                                                            </CardBody>
                                                        </Card>
                                                    ))}
                                                </div>
                                                ) : (
                                                    <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                                                        <FontAwesomeIcon icon="shopping-cart" className="mt-4 text-4xl text-gray-500/50 mb-4" />
                                                        <Typography placeholder="" className="text-base text-gray-700 mb-4">{t("noOrder")}</Typography>
                                                    </div>
                                                )}
                                            </TabPanel>
                                            <TabPanel value="subs" className="overflow-y-auto max-h-[200px] h-[200px] mt-2">
                                                {subs?.data.length > 0 ? (
                                                    <div className="flex flex-col gap-2">
                                                        {subs?.data.map((sub) => (
                                                            <Card placeholder="" key={sub.createdAt}>
                                                                <CardBody placeholder="">
                                                                    <Typography placeholder="" variant="h5" color="blue-gray" className="mb-2">
                                                                        {sub.displayText}
                                                                    </Typography>
                                                                    <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">
                                                                        {moment(sub.createdAt).format("DD.MM.YYYY HH:mm:ss")}
                                                                    </Typography>
                                                                    <Typography placeholder="" variant="small" color="blue-gray" className="mb-2">
                                                                        {sub.paymentMethodName} {sub.status} <b>{sub.amount}</b>{" "}{t("chf")}
                                                                    </Typography>
                                                                </CardBody>
                                                            </Card>
                                                        ))}
                                                    </div>
                                                ) : (
                                                    <div className="flex flex-col justify-center items-center w-full p-4 rounded-lg bg-gray-100">
                                                        <FontAwesomeIcon icon="shopping-cart" className="mt-4 text-4xl text-gray-500/50 mb-4" />
                                                        <Typography placeholder="" className="text-base text-gray-700 mb-4">{t("noSubs")}</Typography>
                                                    </div>
                                                )}
                                            </TabPanel>
                                        </TabsBody>
                                    </Tabs>
                                </CardBody>
                            </Card>
                        </div>
                        <div className="w-full md:w-1/3 md:pr-2 md:pb-2">
                            <Card placeholder="" className="w-full h-full flex flex-col justify-start">
                                <CardBody placeholder="" className="text-left">
                                    <Typography placeholder="" variant="h4" color="blue-gray" className="mb-2 text-center">
                                        {t("navigation")}
                                    </Typography>
                                    <Typography placeholder="" variant="small" color="blue-gray" className="mb-2 text-center">
                                        {t("navigation_d")}
                                    </Typography>
                                    <div className=" max-h-[200px] mt-4 overflow-y-auto">
                                        <table className="w-full text-left">
                                            {navigation?.data.map((nav) => (
                                                <tr key={nav.createdAt} className="border-b border-t">
                                                    <td className="py-1.5">{moment(nav.createdAt).format("DD.MM.YYYY HH:mm")}</td>
                                                    <td className="py-1.5">{nav.requestDetails.country.frLabel}</td>
                                                </tr>
                                            ))}
                                            {navigation?.data.map((nav) => (
                                                <tr key={nav.createdAt} className="border-b border-t">
                                                    <td className="py-1.5">{moment(nav.createdAt).format("DD.MM.YYYY HH:mm")}</td>
                                                    <td className="py-1.5">{nav.requestDetails.country.frLabel}</td>
                                                </tr>
                                            ))}
                                            {navigation?.data.map((nav) => (
                                                <tr key={nav.createdAt} className="border-b border-t">
                                                    <td className="py-1.5">{moment(nav.createdAt).format("DD.MM.YYYY HH:mm")}</td>
                                                    <td className="py-1.5">{nav.requestDetails.country.frLabel}</td>
                                                </tr>
                                            ))}
                                            {navigation?.data.map((nav) => (
                                                <tr key={nav.createdAt} className="border-b border-t">
                                                    <td className="py-1.5">{moment(nav.createdAt).format("DD.MM.YYYY HH:mm")}</td>
                                                    <td className="py-1.5">{nav.requestDetails.country.frLabel}</td>
                                                </tr>
                                            ))}
                                        </table>
                                    </div>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </WrapperGrayZone>
            </div>
        </div>
    );
}
