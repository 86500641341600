import APIClient from "../APIClients";

export default class KeywordsAPI {
    public static async getAll() {
        return APIClient.ezoom.get(`/keywords`);
    }

    public static async newKeyword(data: {}) {
        return APIClient.ezoom.post(`/keywords`, data);
    }

    public static async deleteKeyword(id: string) {
        return APIClient.ezoom.delete(`/keywords/${id}`);
    }

    public static async updateKeyword(id: string, data: {}) {
        return APIClient.ezoom.put(`/keywords/${id}`, data);
    }

    public static async keywordsToApprouve() {
        return APIClient.ezoom.get(`/custom-keywords`);
    }

    public static async keywordsToApprouvePagination(page: number) {
        return APIClient.ezoom.get(`/custom-keywords?page=${page}`);
    }

    public static async acceptKeyword(id: string, body: {}) {
        return APIClient.ezoom.put(`/custom-keywords/${id}/accept`, body);
    }

    public static async refuseKeyword(id: string, body: {}) {
        return APIClient.ezoom.put(`/custom-keywords/${id}/refuse`, body);
    }

    public static async addKeyword(body: {}) {
        return APIClient.ezoom.post(`/keywords`, body);
    }
}