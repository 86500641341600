import APIClient from "../APIClients";

export default class UsersAPI {
    public static async users(query?: string) {
        return APIClient.ezoom.get(`/users${query && query !== undefined && query !== "" ? `?query=${query}` : ""}`);
    }
    public static async usersValidated(query?: string) {
        return APIClient.ezoom.get(`/users?verifiable=true${query && query !== undefined && query !== "" ? `&query=${query}` : ""}`);
    }

    public static async usersPagination(page: number, query?: string) {
        return APIClient.ezoom.get(`/users?page=${page}${query && query !== undefined && query !== "" ? `&query=${query}` : ""}`);
    }

    public static async usersPaginationValidated(page: number, query?: string) {
        return APIClient.ezoom.get(`/users?verifiable=true&page=${page}${query && query !== undefined && query !== "" ? `&query=${query}` : ""}`);
    }

    public static async user(id: string) {
        return APIClient.ezoom.get(`/users/${id}`);
    }

    public static async modifyUser(id: string, data: {}) {
        return APIClient.ezoom.put(`/users/${id}`, data);
    }

    public static async deleteUser(id: string) {
        return APIClient.ezoom.delete(`/users/${id}`);
    }

    public static async changePassword(id: string, data: {}) {
        return APIClient.ezoom.post(`/users/${id}/change-password`, data);
    }

    public static async changeRole(id: string, data: {}) {
        return APIClient.ezoom.put(`/users/${id}/roles`, data);
    }

    public static async changePlan(data: {}) {
        return APIClient.ezoom.post(`/grant-subscription`, data);
    }

    public static async changeStatus(id: string, data: {}) {
        return APIClient.ezoom.put(`/users/${id}/enabled`, data);
    }

    public static async changeUploads(id: string, data: {}) {
        return APIClient.ezoom.put(`/users/${id}/uploads`, data);
    }    

    public static async changeUpload(id: string, data: {}) {
        return APIClient.ezoom.post(`/users/${id}/set-uploads`, data);
    }

    public static async confirmData(id: string) {
        return APIClient.ezoom.put(`/users/${id}/verify`, { manualVerification: "verified" });
    }

    public static async loginLogs(id: string) {
        return APIClient.ezoom.get(`/login-logs?user=${id}`);
    }

    public static async orderTransaction(id: string) {
        return APIClient.ezoom.get(`/order-transactions?user=${id}`);
    }

    public static async subTransaction(id: string) {
        return APIClient.ezoom.get(`/subscription-transactions?user=${id}`);
    }

    public static async completeProfil(id: string, dataTokens: { token: string, refresh_token: string, id: string }) {
        return APIClient.ezoom.getTotp(`/users/${id}/complete`, dataTokens);
    }

    public static async completeMyProfil(id: string, data: {}, dataTokens: { token: string, refresh_token: string, id: string }) {
        return APIClient.ezoom.putTotp(`/users/${id}/complete`, data, dataTokens);
    }

    public static async updateRoles(id: string, data: {}) {
        return APIClient.ezoom.put(`/users/${id}/roles`, data);
    }
}