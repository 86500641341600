import { IconButton, List, ListItem, Popover, PopoverContent, PopoverHandler, Spinner, Typography } from "@material-tailwind/react";
import React from "react";

import Pagination from "./Pagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { confirmAlert } from "react-confirm-alert";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import DefaultPictureEzoom from "../../assets/img/default-profile.png";
import PicturesAPI from "../../services/API/clients/PicturesAPI";
import { Daum } from "../Interface/PictureResearch";
import ModalEditPicture from "../Modals/ModalEditPicture";

interface Props {
    header: { col: string; id: string }[];
    data: Daum[];
    itemsPerPage: { current: number; last: number; totalItems: number; parPage: number };
    callPictures: Function;
}

const TablePicturesResearch: React.FC<Props> = ({ header, data, itemsPerPage, callPictures }) => {
    const { t } = useTranslation();
    const [constantData, setConstantData] = React.useState<boolean[]>([]);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [currentId, setCurrentId] = React.useState<string>("");
    const [openModal, setOpenModal] = React.useState<boolean>(false);
    const [dataToChange, setDataToChange] = React.useState<Daum | null>(null);

    const openModalPicture = (id: string) => {
        const foundData = data.find((element) => element.id === id);
        if (!foundData) return;
        setDataToChange(foundData);
        setOpenModal(true);
    }

    const closeModalPicture = () => {
        setOpenModal(false);
        setDataToChange(null);
        callPictures(itemsPerPage.current);
    }

    const openHandleDeletePicture = async (id: string) => {
        if (!id) return;
        
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className="py-4 bg-white rounded-lg shadow-lg border-2 border-gray-300 w-screen lg:w-[60vw] relative">
                        <FontAwesomeIcon icon="x" className="absolute top-2 right-2 text-gray-500 cursor-pointer" onClick={onClose} />
                        <Typography placeholder="" className="text-center w-full text-2xl">{t("confirmAction")}</Typography>
                        <div className="w-full flex mt-4 justify-center">
                            <div className="w-full flex flex-col px-2 items-center justify-center">
                                <Typography placeholder="" className="text-center w-full mb-4 text-lg">
                                    {t("confirmDeletePicture")}
                                </Typography>
                                <div className="flex gap-4 items-center justify-center">
                                    <button
                                        className="rounded-full border border-orange-500 hover:border-orange-200 bg-orange-500 hover:bg-orange-200 text-white px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans my-2"
                                        onClick={async () => {
                                            const response =  await PicturesAPI.deletePicture(id)
                                            if (response.status === 200 || response.status === 204) {
                                                callPictures(itemsPerPage.current);
                                                toast.success(t("pictureDeleted"))
                                            } else {
                                                toast.error(t("errorPictureDeleted"));
                                            }
                                            callPictures(itemsPerPage.current);
                                            onClose();
                                        }}
                                    >
                                        {t("yes")}
                                    </button>
                                    <button
                                        className="rounded-full border bg-white px-4 py-2 text-sm font-medium hover:shadow-xl transition-all font-sans my-2"
                                        onClick={() => onClose()}
                                    >
                                        {t("no")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                );
            },
        });
    };

    const DropDownReturning = ({ elements }: { elements: { key: string, value: string }[] }) => {
        return (
            <div className="flex items-center justify-center">
                <Popover>
                    <PopoverHandler>
                        <IconButton placeholder="" color="orange">
                            <FontAwesomeIcon icon="bars" />
                        </IconButton>
                    </PopoverHandler>
                    <PopoverContent placeholder="" className="max-h-[300px] bg-white overflow-y-auto z-30 px-1">
                        <List placeholder="">
                            {elements.map((element) => (
                                <ListItem key={element.key} placeholder="">
                                    {element.key} : {element.value}
                                </ListItem>
                            ))}
                        </List>
                    </PopoverContent>
                </Popover>
            </div>
        );
    }

    const writtenDocumentsOnClick = async (id: string) => {
        const response = await PicturesAPI.getWrittenDocuments(id)
        if (response.status === 200) {
            window.open(response.body.path, "_blank")
        }
    }

    const DropDownWrittenDocumentsReturning = ({ elements }: { elements: { id: string, name: string, mime: string }[] }) => {
        return (
            <div className="flex items-center justify-center">
                <Popover>
                    <PopoverHandler>
                        <IconButton placeholder="" color="orange">
                            <FontAwesomeIcon icon="bars" />
                        </IconButton>
                    </PopoverHandler>
                    <PopoverContent placeholder="" className="max-h-[300px] bg-white overflow-y-auto z-30 px-1">
                        <List placeholder="">
                            {elements.map((element) => (
                                <ListItem key={element.id} placeholder="" onClick={() => writtenDocumentsOnClick(element.id)}>
                                    {element.name}
                                </ListItem>
                            ))}
                        </List>
                    </PopoverContent>
                </Popover>
            </div>
        );
    }

    const DateReturning = ({ element, column }: { element: Daum; column: { id: string; col: string } }) => {
        let content = element[column.col as keyof typeof element] ?? "/";
        if (typeof content === "object") {
            content = JSON.stringify(content);
        }

        return (
            <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                {content.toString() === "/" ? content.toString() : moment(content.toString()).format("DD.MM.YYYY HH:mm")}
            </Typography>
        );
    }

    const ImageReturning = ({ keys, element }: { keys: number; element: Daum }) => {
        return (
            <button
                onClick={() => {
                    const oldConstantData = constantData;
                    oldConstantData[keys] = !oldConstantData[keys];
                    setConstantData([...oldConstantData]);
                }}
            >
                <div className="flex items-center overflow-hidden rounded-md" style={{ width: "75px", height: "75px" }}>
                    <img alt="placeholder" className="rounded-md h-full w-full object-cover object-center" src={element?.publicPath ?? DefaultPictureEzoom} />
                </div>
            </button>
        );
    };

    const TypoReturning = ({ element, column }: { element: Daum; column: { id: string; col: string } }) => {
        let content = element[column.col as keyof typeof element] ?? "/";
        if (typeof content === "object") {
            content = JSON.stringify(content);
        }

        return (
            <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                {content}
            </Typography>
        );
    };

    const TypoGallery = ({ element }: { element: Daum }) => {
        return (
            <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                {element?.galleries[0]?.parent?.title ?? "/"}
            </Typography>
        );
    };

    const WatermarkReturning = ({ element }: { element: string }) => {
        let value = ""
        if (element === "bl")
            value = "Bas gauche";
        else if (element === "br")
            value = "Bas droite";
        else if (element === "full")
            value = "Plein";
        else
            value = "none";
        return (
            <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>
                {t(value)}
            </Typography>
        );
    }

    const KeywordsReturning = ({ element }: { element: Daum }) => {
        if (element.keywords === undefined || element.keywords.length === 0)
            return (
                <Typography
                placeholder=""
                variant="small"
                color="blue-gray"
                className={"text-gray-500 whitespace-nowrap"}
                >/</Typography>
            )

        const keywords: {
            index: number;
            keyword: {
                frLabel: string;
                enLabel: string;
            };
        }[] = element.keywords ?? [];

        if (keywords.length === 0)
            return (
                <Typography
                placeholder=""
                variant="small"
                color="blue-gray"
                className={"text-gray-500 whitespace-nowrap"}
                >/</Typography>
            )


        const frLabels = keywords.map((keyword) => keyword.keyword?.frLabel ?? "/");

        return (
            <Typography
            placeholder=""
            variant="small"
            color="blue-gray"
            className={"text-gray-500 whitespace-nowrap"}
            >
                {frLabels.join(", ") || "/"}
            </Typography>
        );
    };

    const handleMotif = async (value: boolean, id: string) => {
        if (loading) return;
        setCurrentId(id);
        const datas = {
            unreferenced: value,
        };
        setLoading(true);
        const response = await PicturesAPI.putPicture(id, datas);
        if (response.status === 200) {
            setLoading(false);
            callPictures(itemsPerPage.current);
            setCurrentId("");
        } else {
            setLoading(false);
            toast.error("Error");
            setCurrentId("");
        }
    };
    
    const ButtonsReturning = (id: string, validationStatus: boolean) => {
        if (loading && id === currentId) return <Spinner color="orange" />;
        return (
            <div className="flex gap-2 justify-center items-center">
                <IconButton
                    onClick={() => handleMotif(!validationStatus, id)}
                    placeholder=""
                    color="green"
                    variant="outlined"
                    className="py-2 px-4"
                >
                    <FontAwesomeIcon icon={validationStatus ? "eye" : "eye-slash"} />
                </IconButton>
                <IconButton
                    onClick={() => openModalPicture(id)}
                    placeholder=""
                    color="orange"
                    variant="outlined"
                    className="py-2 px-4"
                >
                    <FontAwesomeIcon icon="pen" />
                </IconButton>
            </div>
        );
    };

    return (
        <>
            <ModalEditPicture
                open={openModal}
                setOpen={closeModalPicture}
                currentId={dataToChange?.id ?? ""}
                datas={dataToChange}
            />
            <div className="table-container">
                <div className="w-full overflow-x-auto max-h-[52vh]">
                    <table className="w-full min-w-[640px] table-auto overflow-x-scroll overflow-y-auto">
                        <thead>
                            <tr>
                                <th className="border-b border-blue-gray-50 py-3 px-5 text-center"></th>
                                {header?.map((column: { col: string; id: string }, index: number) => (
                                    <th key={column.col} className="border-b border-blue-gray-50 py-3 px-5 text-left">
                                        <Typography
                                            placeholder=""
                                            variant="small"
                                            className={`text-[11px] font-bold uppercase text-blue-gray-400 whitespace-nowrap ${
                                                index === header.length - 1 ? "text-center" : "text-left"
                                            }`}
                                        >
                                            {t(column.id)}
                                        </Typography>
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data?.map((element, key: number) => {
                                const className = `py-1 px-5 ${key === data.length - 1 ? "" : "border-b border-blue-gray-50"}`;

                                return (
                                    <>
                                        <tr key={element.id} className={`example-element-row`}>
                                            <td className={`py-7 border-b flex gap-2 flex-row items-center ${className}`} style={{ textAlign: "center" }}>
                                                <IconButton
                                                    color="red"
                                                    placeholder=""
                                                    variant="outlined"
                                                    onClick={() => {
                                                        openHandleDeletePicture(element.id);
                                                    }}
                                                >
                                                    <FontAwesomeIcon icon="trash" />
                                                </IconButton>
                                                {ButtonsReturning(element.id, element.unreferenced)}
                                            </td>
                                            {header?.map((column: { id: string; col: string }) => {
                                                return (
                                                    <td key={column.id} className={className}>
                                                        {column.id === "picture" && <ImageReturning keys={key} element={element} />}
                                                        {column.id === "exifData" && <DropDownReturning elements={element.exifData} />}
                                                        {column.id === "writtenDocuments" && <DropDownWrittenDocumentsReturning elements={element.writtenDocuments} />}
                                                        {column.id === "owner" && <a href={`/user/${element?.owner?.id}`}><IconButton placeholder="" color="orange"><FontAwesomeIcon icon="user" /></IconButton></a>}
                                                        {column.id === "galleries" && <TypoGallery element={element} />}
                                                        {column.id === "keywords" && <KeywordsReturning element={element} />}
                                                        {(column.id === "uploadedAt" || column.id === "publishedAt") && <DateReturning element={element} column={column} />}
                                                        {(column.id === "category") && <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>{element.category?.frLabel}</Typography>}
                                                        {(column.id === "typeStyle") && <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>{element.category?.frLabel}</Typography>}
                                                        {(column.id === "watermark") && <WatermarkReturning element={(element[column.col as keyof typeof element] ?? "none").toString()} />}
                                                        {(column.id === "license") && <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>{t(element.license)}</Typography>}
                                                        {(column.id === "validationStatus") && <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>{t(element.validationStatus)}</Typography>}
                                                        {(column.id === "nsfw" || column.id === "identifiablePeople" || column.id === "identifiableProperties" || column.id === "writtenContract" || column.id === "watermarkUptoDate" || column.id === "canBeSold" || column.id === "canBeBought") && <Typography placeholder="" variant="small" color="blue-gray" className={"text-gray-500 whitespace-nowrap"}>{element[column.col as keyof typeof element] ? <FontAwesomeIcon icon="check" /> : <FontAwesomeIcon icon="x" />}</Typography>}
                                                        {column.id !== "validationStatus" && column.id !== "keywords" && column.id !== "writtenDocuments" && column.id !== "galleries" && column.id !== "owner" && column.id !== "picture" && column.id !== "exifData" && column.id !== "uploadedAt" && column.id !== "publishedAt" && column.id !== "category" && column.id !== "typeStyle" && column.id !== "watermark" && column.id !== "license" && <TypoReturning element={element} column={column} />}
                                                    </td>
                                                );
                                            })}
                                        </tr>
                                    </>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    handlePageChange={callPictures}
                    itemsPerPage={itemsPerPage.parPage}
                    currentPage={itemsPerPage.current}
                    totalItems={itemsPerPage.totalItems}
                    startIndex={itemsPerPage.current * itemsPerPage.parPage - itemsPerPage.parPage}
                    endIndex={
                        itemsPerPage.current * itemsPerPage.parPage - 1 > itemsPerPage.totalItems
                            ? itemsPerPage.totalItems - 1
                            : itemsPerPage.current * itemsPerPage.parPage - 1
                    }
                />
            </div>
        </>
    );
};

export default TablePicturesResearch;
